<template>
      <div class="container">
		<div class="row">
			<div class="col-12">
				<div class="sign__content">
               <form class="sign__form">
                  <router-link to="/" class="sign__logo">
                     <img src="static/img/logoSvg.svg" alt="">
                  </router-link>
						<div class="sign__group">
                     <p class="" style="font-size: 21px; text-align: center; margin-bottom: 10px; font-family: var(--title-fonts);width: 100%;">حجيتك ما بجيتك</p>
                     <p style="text-align: justify;font-family: var(--title-fonts);font-weight: 600;">

سلسة قصص للأطفال مقدمة من د. هبة المعتمد على نفس صياغ قصص ما قبل النوم. سرد القصص بصورة فيديو مصور حكاوي ملهمه من واقع تراثنا السوداني الاصيل يحث الطفل على التحلي بالقيم النبيلة. وتعتبر بديل جيد لأوقات الفراغ
</p>
                     <!-- <input type="phone" v-model="msisdn" class="sign__input input" autocomplete="off" placeholder="رقم الهاتف" onfocus="this.placeholder = ''" onblur="this.placeholder = '09xxxxxxxx'"> -->
						</div>
                  <div class="sign__group">
                     <!-- <button class="sign__btn" type="submit">تسجيل دخول</button> -->
                     <button class="sign__btn button" style="color:#fff" @click="Subscrib()">اشــتراك
                        <!-- <span v-if="loading" class="px-1 ">جاري اشــتراك</span> &nbsp;
                        <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                        <span v-else class="arabickufi">اشــتراك</span> -->
                     </button>
                     <span class="sign__delimiter"><h6></h6></span>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'AppSubscribes',
    mounted() {
        if (!this.$cookie.isCookieAvailable("msisdn") && !this.$cookie.isCookieAvailable("status")) {
            return 
        } else {
            return this.$router.go(-1)
        }
    },
    setup() {
        const Subscrib = () => {
            window.open("http://test.zaindsp.com:3033/?p=1828871198");
        }
        return { Subscrib }
    }
}
</script>

<style>


</style>